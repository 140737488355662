import React, { useMemo } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import I18n from '../i18n/I18n';
import { H } from '../../common/Layout';
import { useStepper } from './StepperContext';
import Loading from './Loading';

interface StepItem {
    label: string;
    component: React.ComponentType<{}>;
}

interface CustomStepperProps {
    steps: StepItem[];
    onSave?: (data: any) => Promise<unknown>;
}

export const StepsIndexConstant = {
    zero: 0,
    one: 1,
    two: 2,
    three: 3
};

export default ({ steps, onSave }: CustomStepperProps) => {
    const {
        activeStep,
        goToNextStep,
        goToPreviousStep,
        checkIsStepComplete,
        getStepData,
        setIsSaveLoading,
        getIsSaveLoading
    } = useStepper();

    const CurrentStepComponent = useMemo(() => {
        return steps[activeStep].component;
    }, [activeStep]);

    const handleSave = () => {
        // Collect all step data
        const allStepData = steps.reduce((acc, step, index) => {
            const data = getStepData(index); // Retrieve data for each step
            return { ...acc, [`${index}`]: data };
        }, {});

        //ToDo: update based on the component usage
        if (allStepData) {
            setIsSaveLoading(true);
            onSave(allStepData).then(() => setIsSaveLoading(false));
        }
    };

    return (
        <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ flex: '0 0 auto' }}>
                <Stepper activeStep={activeStep}>
                    {steps.map((step) => {
                        return (
                            <Step key={step.label}>
                                <StepLabel>
                                    <I18n token={step.label} />
                                </StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
            </Box>
            <Box
                sx={{
                    flex: '1 1 auto',
                    overflowY: 'auto',
                    padding: 1
                }}
            >
                {getIsSaveLoading() ? <Loading /> : <CurrentStepComponent />}
            </Box>
            <Box sx={{ flex: '0 0 auto' }}>
                <H sx={{ justifyContent: 'space-between' }}>
                    <Button color="inherit" disabled={activeStep === 0} onClick={goToPreviousStep} sx={{ m: 1 }}>
                        <I18n token="dialog.back" />
                    </Button>
                    <Button
                        onClick={activeStep === steps.length - 1 ? handleSave : goToNextStep}
                        disabled={!checkIsStepComplete(activeStep) || getIsSaveLoading()}
                        sx={{ m: 1 }}
                    >
                        {activeStep === steps.length - 1 ? <I18n token="dialog.save" /> : <I18n token="dialog.next" />}
                    </Button>
                </H>
            </Box>
        </Box>
    );
};
