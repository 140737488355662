import React, { createContext, useContext, useEffect, useState } from 'react';
import { constants } from '../../../common/constants';
import { DateHelper } from '@bryntum/schedulerpro';
import { isEmpty } from 'lodash';
import { UserContext } from '../../../common/auth/UserContext';
import { searchClient, slimResultsQuery } from '../../../common/list/slimQuery';
import { PLANNING } from '../../../common/nav/apps';
import { toBryntumCalTime, getFirstDayOftheMonth } from '../../../common/utils/dateTime';
import {
    toBryntumFormat,
    getProductsData,
    fetchReservationData,
    getCachedData,
    updateSchedulerData,
    UpdateIDB
} from '../../../scheduler/scheduler';
import {
    toBryntumCalendars,
    toBryntumEventsAndAssignments,
    toBryntumEventsAndAssignmentsForActivity,
    toLineItemsFromProductionActivity,
    toSchedulerLineItemsFromReservations
} from '../../../scheduler/schedulerUtil';
import { log, successToastMessage } from '../../../common/utils/commonUtils';
import { isDebugValue } from '../../../common/utils/DevelAtoms';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
    activitySearchByPage,
    reservationSearchPage,
    SingleFilter
} from '../../../common/utils/incrementalSearchPages';
import { Activity } from 'sr-types/lib/search/v1/graphql';
import { EnvironmentPermissionContext } from '../../../common/auth/EnvironmentPermissionContext';
import { isSchedulerReservationRefetchNeededAtom } from '../../../scheduler/ReservationSchedulerRefetchAtom';

type ReservationDataContextType = {
    batchReservationData: any;
    schedulerStoreData: any; // need to define types for this
    batchSchedulerStoreData: any;
    productData: any;
    dataLoaded: boolean;
    fetchUpdatedReservations: () => void;
};

export const ReservationDataContext = createContext<ReservationDataContextType>(undefined);

export const getCalendars = (activeOrganizationAccount) => {
    return searchClient.query({
        query: slimResultsQuery('Calendar'),
        variables: {
            filters: [{ identifier: 'entity', value: 'Calendar' }],
            page: {
                from: 0,
                size: 200
            }
        },
        fetchPolicy: constants.apolloFetchPolicy,
        context: {
            headers: {
                ownerId: activeOrganizationAccount
            }
        }
    });
};

const daysOfTheWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

export const toBryntumCalendarIntervals = (calendar) => {
    const intervals = [];
    calendar.workingTime.timeIntervals.forEach((currentInterval, index) => {
        if (currentInterval.startTime === currentInterval.endTime) {
            // entire day interval
            let nextDay = '';
            if (index == calendar.workingTime.timeIntervals.length - 1) {
                //last interval
                const nextDayIndex = daysOfTheWeek.findIndex((day) => day == currentInterval.day);
                nextDay = daysOfTheWeek[nextDayIndex + 1] || daysOfTheWeek[0];
            } else {
                nextDay = calendar.workingTime.timeIntervals[index + 1].day;
            }
            const bInterval = {
                recurrentStartDate: `on ${currentInterval.day} at ${toBryntumCalTime(currentInterval.startTime)}`,
                recurrentEndDate: `on ${nextDay} at ${toBryntumCalTime(currentInterval.endTime)}`,
                isWorking: true
            };
            intervals.push(bInterval);
        } else {
            const bInterval = {
                recurrentStartDate: `on ${currentInterval.day} at ${toBryntumCalTime(currentInterval.startTime)}`,
                recurrentEndDate: `on ${currentInterval.day} at ${toBryntumCalTime(currentInterval.endTime)}`,
                isWorking: true
            };
            intervals.push(bInterval);
        }
    });
    return intervals;
};

/** fetches reservations since the last updated date  */
export const getLastestReservationData = (activeOrganizationAccount, lastUpdatedDate) => {
    return searchClient.query({
        query: slimResultsQuery('Reservation'),
        variables: {
            filters: [
                { identifier: 'entity', value: 'Reservation' },
                {
                    identifier: 'updatedOn',
                    dateMin: lastUpdatedDate
                }
            ],
            page: {
                from: 0,
                size: 50
            }
        },
        fetchPolicy: constants.apolloFetchPolicy,
        context: {
            headers: {
                ownerId: activeOrganizationAccount
            }
        }
    });
};

export const ReservationDataProvider = ({ children }) => {
    const { activeOrganizationAccount, application } = useContext(UserContext);
    const [schedulerStoreData, setSchedulerStoreData] = useState(undefined);
    const [productData, setProductData] = useState(undefined);
    const [calendarData, setCalendarData] = useState(undefined);
    const [reservationData, setReservationData] = useState([]);
    const [activityData, setActivityData] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [batchReservationData, setBatchReservationData] = useState(reservationData);
    const [batchActivityData, setBatchAcitvityData] = useState(activityData);
    const [batchSchedulerStoreData, setBatchSchedulerStoreData] = useState(undefined);
    const [isAllDataLoaded, setIsAllDataLoaded] = useState(false);
    const [lastFetchedAt, setLastFetchedAt] = useState(undefined);
    const isDebug = useRecoilValue(isDebugValue);
    const schedulerPreviewStore = 'schedulerPreviewData';
    const [isSchedulerReservationRefetchNeeded, setIsSchedulerReservationRefetchNeeded] = useRecoilState(
        isSchedulerReservationRefetchNeededAtom
    );

    let instanceName = '_schedulerData';
    if (application?.name === PLANNING.name) {
        instanceName = '_schedulerProductionData';
    }

    const resetStates = () => {
        setDataLoaded(false);
        setIsAllDataLoaded(false);
        setProductData(undefined);
        setReservationData([]);
        setBatchReservationData([]);
        setBatchSchedulerStoreData(undefined);
        setSchedulerStoreData(undefined);
    };

    useEffect(() => {
        resetStates();
        getCachedData(activeOrganizationAccount, schedulerPreviewStore).then((data) => {
            let hoursSinceLastFetch;
            if (!isEmpty(data)) {
                hoursSinceLastFetch = DateHelper.as('hour', Date.now() - data.lastFetchedAt.getTime(), 'ms');
            }
            if (isEmpty(data) || hoursSinceLastFetch > 1) {
                // load products
                const productPromise = getProductsData(activeOrganizationAccount);
                const calendarPromise = getCalendars(activeOrganizationAccount);
                Promise.all([productPromise, calendarPromise]).then((res) => {
                    const products = res[0].data?.results?.hits?.items || [];
                    const calendarData = res[1].data?.results?.hits?.items || [];
                    setProductData(products);
                    setCalendarData(toBryntumCalendars(calendarData));
                    // load reservations

                    const date = getFirstDayOftheMonth();
                    const filters: SingleFilter[] = [
                        { filter: { identifier: 'enddate', dateMin: date }, sortBy: 'enddate_ASC' },
                        {
                            filter: { identifier: 'enddate', dateMax: date, maxValueExcluded: true },
                            sortBy: 'enddate_DESC'
                        }
                    ];
                    setLastFetchedAt(new Date());
                    const reservationPromises = Promise.all(
                        reservationSearchPage(
                            activeOrganizationAccount,
                            200,
                            filters,
                            setBatchReservationData,
                            setReservationData,
                            2
                        )
                    ).then((reservationResults) => {
                        const reservationData = reservationResults.flatMap((reservations) => reservations);
                        setReservationData(reservationData);
                    });

                    const activityPromises = Promise.all(
                        activitySearchByPage(
                            activeOrganizationAccount,
                            200,
                            filters,
                            setBatchAcitvityData,
                            setActivityData,
                            2,
                            false,
                            'ProductReferences'
                        )
                    ).then((activityResults) => {
                        const activityData: Activity[] = activityResults.flatMap((activities) => activities);
                        const lineItems = activityData
                            .map((activity) => toLineItemsFromProductionActivity([activity]))
                            .flat();
                        setActivityData(lineItems);
                    });

                    Promise.all([reservationPromises, activityPromises]).then(() => {
                        setDataLoaded(true);
                        setIsAllDataLoaded(true);
                    });
                });
            } else {
                setProductData(data.resources);
                setIsAllDataLoaded(true);
                getData(data).then((data: { formattedData: any; results: any[] }) => {
                    setSchedulerStoreData(data.formattedData);
                    setBatchSchedulerStoreData(data.formattedData);
                });
            }
        });
    }, [activeOrganizationAccount]);

    useEffect(() => {
        if (productData && reservationData && activityData && calendarData) {
            const formattedReservationData = toBryntumFormat(reservationData, productData, calendarData);
            const { events, assignments } = toBryntumEventsAndAssignmentsForActivity(activityData, application);
            formattedReservationData.events = formattedReservationData.events.concat(events);
            formattedReservationData.assignments = formattedReservationData.assignments.concat(assignments);
            if (lastFetchedAt) {
                formattedReservationData.lastFetchedAt = lastFetchedAt;
            }
            setSchedulerStoreData(formattedReservationData);
        }
    }, [reservationData, activityData, productData, calendarData]);

    useEffect(() => {
        // convert to brynum resources and events and setBatchSchedulerStoreData

        if (productData && batchReservationData && batchActivityData && calendarData) {
            const { events, assignments } = toBryntumEventsAndAssignmentsForActivity(activityData, application);
            const data = toBryntumFormat(batchReservationData, productData, calendarData);
            data.events = [...data.events, ...events];
            data.assignments = [...data.assignments, ...assignments];
            setBatchSchedulerStoreData(data);
        }
    }, [productData, batchReservationData, batchActivityData, calendarData]);

    const getData = (schedulerStoreData) => {
        return new Promise((resolve, reject) => {
            getLastestReservationData(activeOrganizationAccount, schedulerStoreData.lastFetchedAt).then((res) => {
                const results = res?.data?.results?.hits?.items;
                const newData = { ...schedulerStoreData };
                let schedulerLineItems = [];
                if (results && results.length) {
                    successToastMessage('timeline.refetch.message', { count: results.length || 0 });
                    schedulerLineItems = toSchedulerLineItemsFromReservations(results ?? []);
                    const { events, assignments } = toBryntumEventsAndAssignments(schedulerLineItems);
                    const newLineIds = events.map((event) => event.lineItemId);
                    const filteredEvents = newData.events.filter((event) => !newLineIds.includes(event.lineItemId));
                    newData.events = [...filteredEvents, ...events];
                    newData.assignments = [
                        ...newData.assignments.filter((assignment) => !newLineIds.includes(assignment.lineItemId)),
                        ...assignments
                    ];
                    newData.lastFetchedAt = new Date();
                    updateSchedulerData(
                        schedulerPreviewStore,
                        schedulerLineItems,
                        activeOrganizationAccount,
                        false,
                        true
                    );
                }

                resolve({ formattedData: newData, results: results });
            });
        });
    };

    const fetchUpdatedReservations = () => {
        if (schedulerStoreData && isAllDataLoaded) {
            getData(schedulerStoreData).then((data: { formattedData: any; results: any[] }) => {
                if (data?.results?.length) {
                    setSchedulerStoreData(data.formattedData);
                    const batchData = toBryntumFormat(data.results, productData, calendarData);
                    setBatchSchedulerStoreData(batchData);
                }
            });
        }
    };

    useEffect(() => {
        if (schedulerStoreData && isSchedulerReservationRefetchNeeded) {
            getCachedData(activeOrganizationAccount, schedulerPreviewStore).then((data) => {
                if (!isEmpty(data)) {
                    setSchedulerStoreData((prevState) => ({
                        ...prevState,
                        assignments: [...data.assignments],
                        events: [...data.events]
                    }));
                }
            });
        }
    }, [isSchedulerReservationRefetchNeeded, activeOrganizationAccount]);

    useEffect(() => {
        // store data to indexed db once all batches are fetched
        schedulerStoreData &&
            dataLoaded &&
            UpdateIDB(activeOrganizationAccount, schedulerPreviewStore, schedulerStoreData);
    }, [dataLoaded, schedulerStoreData]);

    return (
        <ReservationDataContext.Provider
            value={{
                batchReservationData,
                schedulerStoreData,
                productData,
                batchSchedulerStoreData,
                dataLoaded,
                fetchUpdatedReservations
            }}
        >
            {children}
        </ReservationDataContext.Provider>
    );
};
